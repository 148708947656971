import translationEN from "./locales/en/translation.json";
import translationDE from "./locales/de/translation.json";
import { enUS, de } from "date-fns/locale";

/**
 * Main configuration
 * apiUrl: full URL of the API to use without server due to proxy settings
 * secondsBeforeTokenRefresh: amount of minimum seconds before expiration at which the service will re-request an accessToken
 */
export const config = {
    apiUrl: '/api/v1',
    secondsBeforeTokenRefresh: 180,
    tokenRefreshCheckInterval: 5000,
    autoLogin: {
        enabled: false
    },
    mainTitle: "TimeDB aeroLifi",
    mainDrawerWidth: 200,
    targetBaseUrl: "/main",
    showContainerPageDetails: false,
    buildInfo: {
        version: process.env['REACT_APP_BUILDVERSION'] || "manual",
        timestamp: process.env['REACT_APP_BUILDTIMESTAMP'] || "rightnow",
        type: process.env['REACT_APP_BUILDTYPE'] || "manual",
        tag: process.env['REACT_APP_TAG'] || "manual",
        cfg: process.env['REACT_APP_CFG'] || "manual"
    },
    userPassword: {
        minLength: 8
    },
    copyright: {
        companyName: "Atmosphere GmbH",
        url: "http://www.atmosphere.aero"
    },
    frontendLanguages: [
        { 
            id: "en",
            name: "English",
            resource: translationEN,
            dateLocale: enUS
        }
    ],
    frontendFallbackLanguage: "en",
    alertMessageTimeout: 3000,
    generateHolidaysDefault: {
        country: "de",
        region: "BY"
    },
    contractDefault: {
        workDaysPerWeek: "1111100",
        workHoursPerWeek: 40,
        vacationDaysPerYear: 30,
        vacationDaysAtStart: 0,
        workHoursAtStart: 0
    },
    specialIfHidden: {
        name: "Comes from language file",
        short_name: "X",
        color_code: "000000"
    },
    specialTypeLinkedToVacations: "vacation",
    workTimes: {
        defaultWorkStartTime: "08:00:00",
        trackStartDate: "2023-05-01",
        break_short: 0.5,
        break_long: 0.75,
        minimum_rest_period: 11
    },
    enums: {
        specialType: [
            {
                id: "none",
                name: "enumerations.special_type.none"
            },
            {
                id: "vacation",
                name: "enumerations.special_type.vacation"
            }
        ],
        projectStatus: [
            {
                id: "open",
                name: "enumerations.project_status.open"
            },
            {
                id: "done",
                name: "enumerations.project_status.done"
            },
            {
                id: "closed",
                name: "enumerations.project_status.closed"
            }
        ],
        taskStatus: [
            {
                id: "open",
                name: "enumerations.task_status.open"
            },
            {
                id: "closed",
                name: "enumerations.task_status.closed"
            }
        ]
    },
    maxTextLengths: {
        accounting: {
            comment: 255
        },
        user: {
            email: 100,
            name: 100
        },
        team: {
            name: 100
        },
        task: {
            name: 100
        },
        special: {
            name: 100,
            shortName: 1
        },
        role: {
            name: 100
        },
        project: {
            internalId: 50,
            name: 100
        },
        holiday: {
            name: 100
        },
        userType: {
            name: 100
        }
    }
}

// Get the date locale object for the given language
export function getDateLocale( language ) {
    for (let i = 0; i < config.frontendLanguages.length; i++) {
        if( config.frontendLanguages[i].id === language ) {
            return config.frontendLanguages[i].dateLocale;
        }
    }
    // Return english as fallback
    return enUS;
}

// Get all available languages
export function getAvailableLanguages() {
    // Assemble array of languages from config
    let languages = [];
    for (let i = 0; i < config.frontendLanguages.length; i++) {
        languages.push( config.frontendLanguages[i].id );
    }
    return languages;
}

// Get the fallback language
export function getFallbackLanguage() {
    return config.frontendFallbackLanguage;
}

// Assemble json of language resources from config
export function getLanguageResources() {
    let resources = {};
    for (let i = 0; i < config.frontendLanguages.length; i++) {
        let id = config.frontendLanguages[i].id;
        let resource = config.frontendLanguages[i].resource;

        resources[id] = {
            translation: resource
        }
    }
    return resources;
}
